import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				Trim Line Барбершоп
			</title>
			<meta name={"description"} content={"Створена з пристрасті до позачасового стилю і мистецтва догляду, The Trim Line була створена з метою відродити класичну атмосферу перукарні, включивши до неї сучасні штрихи, що відповідають потребам сучасного чоловіка."} />
			<meta property={"og:title"} content={"Trim Line Барбершоп"} />
			<meta property={"og:description"} content={"Створена з пристрасті до позачасового стилю і мистецтва догляду, The Trim Line була створена з метою відродити класичну атмосферу перукарні, включивши до неї сучасні штрихи, що відповідають потребам сучасного чоловіка."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d78e8d4a0c00202f1164/images/3-1.jpg?v=2024-05-07T09:55:58.195Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="--headline1" color="--light">
				Відповіді на ваші запитання
			</Text>
			<Text margin="0px 0px 70px 0px" font="--base" color="--light" sm-margin="0px 0px 50px 0px">
				У The Trim Line ми прагнемо відповісти на ваші запитання і забезпечити безперебійну роботу в нашій перукарні. Ми розуміємо, що перед візитом у вас можуть виникнути запитання, і ми тут, щоб надати вам усю необхідну інформацію. У цьому розділі описані найпоширеніші запити, які ми отримуємо, але якщо ви не знайшли те, що шукаєте, не соромтеся звертатися до нас безпосередньо.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Які послуги Ви надаєте?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми надаємо широкий спектр послуг, включаючи стрижки, догляд за бородою, гоління, догляд за волоссям та укладання для особливих випадків. Кожна послуга виконується максимально ретельно, щоб задовольнити ваші потреби в стилі.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Чи потрібно записуватись на прийом?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Хоча входи вітаються, запис на прийом забезпечує мінімальний час очікування та гарантоване місце у нашому розкладі, особливо у вихідні.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Які у вас години роботи?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Наш магазин відкритий з 9:00 до 19:00 з понеділка до суботи. Ми закриті в неділю.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Скільки триває стандартний прийом?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Стандартна стрижка може зайняти близько 30 хвилин, а складніші послуги, такі як фарбування або спеціальне укладання, можуть тривати довше. Ми радимо виділити трохи додаткового часу на консультацію та останні штрихи.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Чи можу я обрати перукаря?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Абсолютно! Якщо у вас є улюблений перукар, вкажіть його під час запису на прийом. Ми хочемо, щоб ви відчували себе комфортно і отримували задоволення від догляду за собою.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="--lead" color="--light">
						Які продукти ви використовуєте?
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--light">
						Ми використовуємо професійну продукцію преміум-класу, яка підходить для всіх типів шкіри та волосся. Наші продукти також доступні для придбання домашнього догляду.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});